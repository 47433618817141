import gql from 'graphql-tag';

export const GetLicensedProductsQuery = gql`
	query GetLicensedProducts {
		confluence_tenantContext {
			licensedProducts {
				licenseStatus
				productKey
			}
		}
	}
`;
